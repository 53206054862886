import { VerticalSpacing } from "@einride/ui"
import styled from "@emotion/styled"
import { Col, ContentWrapper, Row } from "components/shared/styles/Layout"
import { Col2 } from "components/ui/Col2/Col2"
import { Layout } from "components/ui/Layout/Layout"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { mediaQueries } from "lib/theming/breakpoint"
import { Paragraph, Title1, Title2 } from "lib/ui/Typography/Typography"
import { ReactNode } from "react"

interface ContactViewProps {
  children: ReactNode
  imageData: { gatsbyImageData: IGatsbyImageData; title: string }
  title?: string
  preamble?: ReactNode
}

export const ContactView = ({
  imageData,
  title,
  preamble,
  children,
}: ContactViewProps): JSX.Element => {
  return (
    <Layout>
      <VerticalSpacing size="sm" />
      <ContactIntro>
        <Title1>{title || "Connect with us"}</Title1>
        {preamble && (
          <>
            <VerticalSpacing size="md" />
            {preamble}
          </>
        )}
      </ContactIntro>
      <VerticalSpacing size="lg" />
      <ContentWrapper>
        <StyledRow>
          <Col width={1 / 2}>{children}</Col>
          <Col width={1 / 2}>
            <StyledGatsbyImage image={imageData.gatsbyImageData} alt={imageData.title} />
          </Col>
        </StyledRow>
      </ContentWrapper>
      <VerticalSpacing size="lg" />
      <ContentWrapper>
        <Col2>
          <Title2>Our offices</Title2>
          <>
            <Paragraph>
              Regeringsgatan 65
              <br />
              Stockholm, 111 56
              <br />
              Sweden
            </Paragraph>
            <VerticalSpacing />
            <Paragraph>
              Gullbergs Strandgatan 15
              <br />
              Gothenburg, 411 04
              <br />
              Sweden
            </Paragraph>
            <VerticalSpacing />
            <Paragraph>
              220 E 42nd Street, Suite #409A
              <br />
              New York, NY 10017
              <br />
              USA
            </Paragraph>
            <VerticalSpacing />
            <Paragraph>
              600 Congress Ave
              <br />
              Austin, TX 78 701
              <br />
              USA
            </Paragraph>
          </>
        </Col2>
      </ContentWrapper>
      <VerticalSpacing size="xl" />
    </Layout>
  )
}

const ContactIntro = styled(ContentWrapper)`
  width: 566px;
  max-width: 100%;
`

const StyledGatsbyImage = styled(GatsbyImage)`
  display: none;
  @media ${mediaQueries.md} {
    display: block;
  }
`

const StyledRow = styled(Row)`
  > div:not(:last-of-type) {
    padding-right: 0;
  }
  > div:not(:first-of-type) {
    padding-left: 0;
  }

  @media ${mediaQueries.md} {
    > div:not(:last-of-type) {
      padding-right: 12px;
    }
    > div:not(:first-of-type) {
      padding-left: 12px;
    }
  }
`
