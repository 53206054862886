import { Caption, Paragraph, VerticalSpacing } from "@einride/ui"
import styled from "@emotion/styled"
import { WindowLocation } from "@reach/router"
import { ContactForm } from "components/fragments/ContactForm/ContactForm"
import { Seo } from "components/fragments/Seo/Seo"
import { Link } from "components/ui/Link/Link"
import { ContactView } from "components/views/ContactView/ContactView"
import { graphql } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { ThemeProvider } from "lib/theming/ThemeProvider/ThemeProvider"

interface ContactProps {
  data: {
    contentfulAsset: { gatsbyImageData: IGatsbyImageData; title: string }
  }
  location: WindowLocation
}

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.content.positive};
`

const StyledContact = styled.div`
  background: ${({ theme }) => theme.colors.background.primary};
`

const Contact = ({ data, location }: ContactProps): JSX.Element => {
  return (
    <>
      <Seo pathname={location.pathname} title="Contact" />
      <ThemeProvider colorMode="light">
        <StyledContact>
          <ContactView
            imageData={data.contentfulAsset}
            title="Contact"
            preamble={
              <>
                <Paragraph>
                  Have a question or comment? Submit your message through our contact form and a
                  member of our team will get back to you as soon as possible.
                </Paragraph>
                <VerticalSpacing />
                <Paragraph>
                  Interested in joining us?
                  <br />
                  Please visit our <StyledLink to="/careers">Career site</StyledLink> to view all
                  current openings.
                </Paragraph>
                <Caption>
                  Potential candidates will be contacted by an Einride team member with an authentic
                  einride.tech email account or via a certified Einride recruiter with a verified
                  work email on LinkedIn. After the initial phase of the recruitment process,
                  multiple face-to-face interviews are held, in person or online. Einride does not
                  ask candidates to pay for the hiring process or to purchase equipment. If you have
                  any questions, please reach out to us.
                </Caption>
              </>
            }
          >
            <ContactForm leadSource="Contact form" />
          </ContactView>
        </StyledContact>
      </ThemeProvider>
    </>
  )
}

export const query = graphql`
  query {
    contentfulAsset(file: { fileName: { eq: "einride_aet.jpg" } }) {
      gatsbyImageData
      title
    }
  }
`

export default Contact
